import React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './src/utils/linkResolver'
import {AnimatePresence} from 'framer-motion';

window.loaded = false;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
    // transition duration from `layout.js` * 1000 to get time in ms
    // * 2 for exit + enter animation
    const TRANSITION_DELAY = 0.3 * 1000 * 2


    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)

    // // if it's a "normal" route
    // if (location.action === "PUSH") {
    //     window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
    // }

    // // if we used the browser's forwards or back button
    // else {
    //     const savedPosition = getSavedScrollPosition(location) || [0, 0]

    //     window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
    // }

    return false
}

export const wrapPageElement = ({element}) => (

    <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver,
          },
        ]}
        >
        <AnimatePresence mode="wait">
            {element}
        </AnimatePresence>
    </PrismicPreviewProvider>
);