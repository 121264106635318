exports.linkResolver = (doc) => {

  // URL for a category type
  if (doc.type === 'homepage') {
    return `/`
  }

  if (doc.type === 'category') {
    // console.log('category / doc', doc);

    // const _cat = doc.slug
    return `/projets/`
  }

  if (doc.type === 'project') {
    // console.log('project / doc', doc);
    // const _cat = doc.data.category.slug;
    // const _proj = doc.slug

    return `/projets/`
  }

  if (doc.type === 'service') {
    return `/prestations/`
  }

  if (doc.type === 'about') {
    return `/a-propos/`
  }

  if (doc.type === 'contact') {
    return `/contact/`
  }

  if (doc.type === 'legals') {
    return `/mentions-legales/`
  }


  // Backup for all other types
  return '/'
}
