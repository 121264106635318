module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"maison-maslag","accessToken":"MC5aU2JSWnhBQUFDSUFzUFA0.77-9H--_vRjvv70N77-9X0Xvv70a77-977-977-9VHd-eFDvv73vv70Sdu-_ve-_ve-_vRrvv73vv73vv70677-9","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6Im1haXNvbi1tYXNsYWctYjM0MTAwZmEtMDEyNS00MTllLTlkYjMtZmFkZDVhZjc5MDFjXzQiLCJkYXRlIjoxNjk3MTAxMDM4LCJkb21haW4iOiJtYWlzb24tbWFzbGFnIiwiaWF0IjoxNjk3MTAxMDM4fQ.4B0AeJk5VbFuJunSi5DzYpbRxp5nTifwfCPXlqmsxyE","linkResolver":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#d2d2d1","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"27fe19686693f80e65cec84b0c8f6938"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-XBN5NWSE2C","routeChangeEventName":"Navigate","includeInDevelopment":false,"defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
